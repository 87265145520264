import React from "react"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function TravisPage(props) {
  return (
    <div id="audemars-piguet">
      <VideoMobile
        title="Audemars Piguet, Matthew Williams in Imagination Land"
        src="https://player.vimeo.com/video/858136001?title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="Audemars Piguet, Matthew Williams in Imagination Land"
        src="https://player.vimeo.com/video/858136001?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
    </div>
  )
}
